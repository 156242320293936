<template>
  <b-sidebar
    id="sidebarFilter"
    v-model="isShowSidebar"
    title="FILTER"
    aria-labelledby="sidebar-no-header-title"
    no-header
    backdrop
    shadow
    right
    ref="sidebarFilter"
    @hidden="hide"
    width="80vw"
  >
    <div class="px-3 mb-3">
      <b-row class="header-filter">
        <b-col class="text-filter"> Filter </b-col>
        <b-col class="text-right">
          <button class="btn-clear" @click.prevent="clearFilter">
            x <span class="text-under">Clear Filter</span>
          </button>
        </b-col>
      </b-row>

      <b-row>
        <b-col cols="12" class="mt-3">
          <InputDropdownCheckbox
            ref="member_tiers"
            textFloat="Member Tier"
            name="member-tier"
            :valueSelectedAll="selectedAll.member_tiers"
            :value="filter.member_tiers"
            :options="options.member_tier"
            valueField="id"
            textField="memberTierName"
            @onDataChangeCheckAll="(val) => (selectedAll.member_tiers = val)"
            @onDataChange="(val) => (filter.member_tiers = val)"
          />
        </b-col>

        <b-col cols="12" class="mt-3">
          <InputDropdownCheckbox
            ref="channel"
            textFloat="Channel"
            name="channel"
            :valueSelectedAll="selectedAll.channel"
            :value="filter.channel_ids"
            :options="options.channel"
            valueField="id"
            textField="channelName"
            @onDataChangeCheckAll="(val) => (selectedAll.channel = val)"
            @onDataChange="(val) => (filter.channel_ids = val)"
          />
        </b-col>
        <b-col cols="12" class="mt-3">
          <InputDropdownCheckbox
            ref="branch_ids"
            textFloat="Purchase Branch"
            name="purchase_branch"
            :valueSelectedAll="selectedAll.branch_ids"
            :value="filter.branch_ids"
            :options="options.purchase_branch"
            valueField="id"
            textField="branchName"
            @onDataChangeCheckAll="(val) => (selectedAll.branch_ids = val)"
            @onDataChange="(val) => (filter.branch_ids = val)"
          />
        </b-col>

        <b-col cols="12" class="mt-3">
          <InputDropdownCheckbox
            ref="age_group_ids"
            textFloat="Age Group"
            name="age_group"
            :valueSelectedAll="selectedAll.age_group_ids"
            :value="filter.age_group_ids"
            :options="options.age_group"
            valueField="id"
            textField="ageGroupName"
            @onDataChangeCheckAll="(val) => (selectedAll.age_group_ids = val)"
            @onDataChange="(val) => (filter.age_group_ids = val)"
          />
        </b-col>
        <b-col cols="12" class="mt-3">
          <InputDropdownCheckbox
            ref="genders"
            textFloat="Gender"
            name="gender"
            :valueSelectedAll="selectedAll.genders"
            :value="filter.genders"
            :options="options.gender"
            valueField="id"
            textField="genderGroupName"
            @onDataChangeCheckAll="(val) => (selectedAll.genders = val)"
            @onDataChange="(val) => (filter.genders = val)"
          />
        </b-col>
        <b-col cols="12" class="mt-3">
          <label class="typo__label main-label mb-0">Barcode / Product</label>
          <multiselect
            :close-on-select="false"
            :clear-on-select="false"
            v-model="filter.product"
            :options="options.product"
            multiple
            placeholder="Select Barcode / Product"
            track-by="value"
            label="text"
          ></multiselect>
        </b-col>

        <b-col cols="12" class="mt-3">
          <InputDropdownCheckbox
            ref="recencys"
            textFloat="Recency"
            name="recencys"
            :valueSelectedAll="selectedAll.recencys"
            :value="filter.recencys"
            :options="options.recency"
            @onDataChangeCheckAll="(val) => (selectedAll.recencys = val)"
            @onDataChange="(val) => (filter.recencys = val)"
          />
        </b-col>

        <b-col cols="12" class="mt-3">
          <InputDropdownCheckbox
            ref="frequency"
            textFloat="Frequency"
            name="frequency"
            :valueSelectedAll="selectedAll.frequency"
            :value="filter.frequency"
            :options="options.frequency"
            @onDataChangeCheckAll="(val) => (selectedAll.frequency = val)"
            @onDataChange="(val) => (filter.frequency = val)"
          />
        </b-col>

        <b-col cols="12" class="mt-3">
          <InputDropdownCheckbox
            ref="monetary"
            textFloat="Monetary"
            name="monetary"
            :valueSelectedAll="selectedAll.monetary"
            :value="filter.monetary"
            :options="options.monetary"
            @onDataChangeCheckAll="(val) => (selectedAll.monetary = val)"
            @onDataChange="(val) => (filter.monetary = val)"
          />
        </b-col>

        <b-col cols="12" class="mt-3">
          <InputDropdownCheckbox
            ref="repurchase"
            textFloat="Repeat Repurchase"
            name="repeat_repurchase"
            :valueSelectedAll="selectedAll.repurchase"
            :value="filter.repurchase"
            :options="options.repurchase"
            @onDataChangeCheckAll="(val) => (selectedAll.repurchase = val)"
            @onDataChange="(val) => (filter.repurchase = val)"
          />
        </b-col>
      </b-row>
    </div>
    <template #footer>
      <div class="d-flex justify-content-between shadow align-items-center">
        <div class="w-100">
          <b-button
            block
            variant="dark"
            class="text-light rounded-pill"
            @click.prevent="hide"
          >
            Cancel
          </b-button>
        </div>
        <div class="w-100">
          <b-button
            @click.prevent="submitAction"
            block
            variant="light"
            class="text-body rounded-pill btn-main"
          >
            Search
          </b-button>
        </div>
      </div>
    </template>
  </b-sidebar>
</template>

<script>
import InputDropdownCheckbox from "@/components/inputs/InputDropdownCheckbox";
import InputDatePickerFilter from "@/components/inputs/InputDatePickerFilter";
import Multiselect from "vue-multiselect";

export default {
  components: {
    InputDropdownCheckbox,
    InputDatePickerFilter,
    Multiselect,
  },

  watch: {
    "filter.firstPurchaseDateTo"(newValue) {
      this.filter.firstPurchaseDateTo = newValue
        ? this.setEndTime(newValue)
        : null;
    },
    "filter.lastPurchaseDateTo"(newValue) {
      this.filter.lastPurchaseDateTo = newValue
        ? this.setEndTime(newValue)
        : null;
    },
    "filter.registerDateTo"(newValue) {
      this.filter.registerDateTo = newValue ? this.setEndTime(newValue) : null;
    },
    "filter.transactionDateTo"(newValue) {
      this.filter.transactionDateTo = newValue
        ? this.setEndTime(newValue)
        : null;
    },
  },
  data() {
    return {
      showPopup: true,
      isShowSidebar: false,
      date: new Date().toISOString(),
      defaultData: {
        dateFrom: null,
        dateTo: null,
        minDatetime: new Date().toISOString(),
        maxDatetime: new Date().toISOString(),
      },
      selectedAll: {
        member_tiers: false,
        branch_ids: false,
        age_group_ids: false,
        channel: false,
        product: false,

        recencys: false,
        frequency: false,
        genders: false,
        monetary: false,
        repurchase: false,
      },
      selectedAllFreeze: {
        member_tiers: false,
        branch_ids: false,
        age_group_ids: false,
        channel: false,
        product: false,

        recencys: false,
        frequency: false,
        genders: false,
        monetary: false,
        repurchase: false,
      },
      filter: {
        rate_scale: 5,
        member_tiers: [],
        channel_ids: [],
        branch_ids: [],
        age_group_ids: [],
        genders: [],
        products: [],
        product: [],
        recencys: [],
        frequency: [],
        monetary: [],
        repurchase: [],
      },
      filterFreeze: {
        rate_scale: 5,
        member_tiers: [],
        channel_ids: [],
        branch_ids: [],
        age_group_ids: [],
        genders: [],
        products: [],
        product: [],
        recencys: [],
        frequency: [],
        monetary: [],
        repurchase: [],
      },
      options: {
        channel: [],
        product: [{ text: "", value: "" }],
        customerLifeCycle: [
          { text: "Min", value: 1 },
          { text: "Average Min", value: 2 },
          { text: "Average", value: 3 },
          { text: "Average Max", value: 4 },
          { text: "Max", value: 5 },
        ],
        recencys: [
          { text: "1", value: 1 },
          { text: "2", value: 2 },
          { text: "3", value: 3 },
          { text: "4", value: 4 },
          { text: "5", value: 5 },
        ],
        frequency: [
          { text: "1", value: 1 },
          { text: "2", value: 2 },
          { text: "3", value: 3 },
          { text: "4", value: 4 },
          { text: "5", value: 5 },
        ],
        monetary: [
          { text: "1", value: 1 },
          { text: "2", value: 2 },
          { text: "3", value: 3 },
          { text: "4", value: 4 },
          { text: "5", value: 5 },
        ],
      },
    };
  },
  created() {
    let now = new Date();
    let dateFrom = this.$moment(now)
      .add(-31, "day")
      .set({ hour: 0, minute: 0, second: 0 })
      .format();
    let dateTo = this.$moment(now)
      .set({ hour: 23, minute: 59, second: 59 })
      .format();
    this.defaultData.dateFrom = dateFrom;
    this.defaultData.dateTo = dateTo;
    this.defaultData.minDatetime = this.$moment(now).add(-1, "year").format();
    this.getFilterOption();

    // this.submitAction();
  },
  methods: {
    async getFilterOption() {
      const res = await this.$Axios(
        `${process.env.VUE_APP_REPORT_API}/DashBoard/rfm_customfilter`
      );
      const productResponse = await this.$Axios.post(
        `${process.env.VUE_APP_REPORT_API}/DashBoard/rfm_customfilter_product`,
        {
          SearchProduct: "",
          PageNumber: 1,
          RecordPerpage: 9999,
        }
      );

      let payload = res.data.detail;
      this.$nextTick(() => {
        payload.product = productResponse.data.detail.productFilterModels.map(
          (el) => {
            return { text: el.productName, value: el.id };
          }
        );
      });
      this.options = payload;
    },
    show(filter) {
      this.filter = { ...filter };
      this.isShowSidebar = true;
    },
    hide() {
      this.filter = { ...this.filterFreeze };
      this.selectedAll = { ...this.selectedAllFreeze };

      this.isShowSidebar = false;
    },
    submitAction() {
      this.filterFreeze = { ...this.filter };
      this.selectedAllFreeze = { ...this.selectedAll };
      this.$emit("filterSearch", this.filter);
      this.hide();
    },
    clearFilter() {
      this.initFilter();
    },
    initFilter() {
      this.selectedAll = {
        member_tiers: false,
        branch_ids: false,
        age_group_ids: false,
        channel: false,
        product: false,

        recencys: false,
        frequency: false,
        genders: false,
        monetary: false,
        repurchase: false,
      };
      this.filter = {
        rate_scale: 5,
        member_tiers: [],
        channel_ids: [],
        branch_ids: [],
        age_group_ids: [],
        genders: [],
        products: [],
        product: [],
        recencys: [],
        frequency: [],
        monetary: [],
        repurchase: [],
      };
      this.isShowSidebar = false;

      this.$refs.member_tiers.clearValue();
      this.$refs.channel.clearValue();
      this.$refs.branch_ids.clearValue();
      this.$refs.age_group_ids.clearValue();
      this.$refs.genders.clearValue();
      this.$refs.recencys.clearValue();
      this.$refs.frequency.clearValue();
      this.$refs.monetary.clearValue();
      this.$refs.repurchase.clearValue();
      this.$emit("clearFilter", this.filter);
    },
    setEndTime(datetime) {
      return this.$moment(datetime)
        .set({ hour: 23, minute: 59, second: 59 })
        .format();
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .input-container input {
  border: none;
}
.text-under {
  text-decoration: underline;
}
.btn-clear {
  background-color: transparent;
  border: none;
}
::v-deep .btn-filter button,
.btn-filter {
  color: var(--primary-color) !important;
  border-color: var(--primary-color);
}
.header-filter {
  background-color: var(--secondary-color);
  //   color: #fff;
  padding: 15px 0;
}
.btn-close {
  background-color: transparent;
  border: none;
  color: #fff;
  font-size: 18px;
}
.text-filter {
  font-weight: 600;
}
::v-deep .date-picker .vdatetime-input {
  width: 100%;
  color: var(--primary-color);
  border: 1px solid #d8dbe0;
  border-radius: 5px;
  padding: 5px 10px;
  margin-bottom: 0.5rem;
}
label {
  margin-bottom: 0.5rem;
  font-weight: 600;
}
::v-deep .b-sidebar > .b-sidebar-body {
  overflow-x: hidden;
}
::v-deep .b-sidebar > .b-sidebar-header {
  font-size: 16px;
  display: block;
  padding: 0 1rem;
}
::v-deep .rounded-pill {
  border-radius: 0px !important;
}
::v-deep .btn-main,
.btn-mains button {
  width: 100% !important;
  background-color: var(--primary-color) !important;
  border: 1px solid var(--primary-color);
}
::v-deep .multiselect__tags {
  min-height: 35px !important;
  border-color: #bcbcbc !important;
  padding: 0.4rem 0.5rem !important;
  .multiselect__placeholder {
    color: #454b51;
    padding-top: unset !important;
    margin-bottom: unset !important;
  }
}
::v-deep .multiselect__option {
  display: flex;
  padding: 5px;
  min-height: 20px;

  text-decoration: none;
  text-transform: none;
  vertical-align: middle;
  position: relative;
  cursor: pointer;
  white-space: nowrap;
  align-items: center;
  font-size: 12px;
  text-overflow: ellipsis;
  &:after {
    top: 0;
    right: 0;
    position: absolute;
    line-height: 20px;
    padding-right: 12px;
    padding-left: 20px;
    font-size: 12px;
  }
}
// ::v-deep .multiselect__option:after {
//   top: 0;
//   right: 0;
//   position: absolute;
//   line-height: 17px;
//   padding-right: 12px;
//   padding-left: 20px;
//   font-size: 13px;
// }
</style>
