p
<template>
  <div class="bg-white">
    <div class="mb-2 mb-sm-3 p-3 pre-box title-panel">
      <b-row>
        <b-col md="3" class="border-right">
          <label class="w-100 mb-2 content-between f-16">
            Retention
            <span
              class="f-16 underline cursor-pointer text-highlight"
              @click="exportModal(5, true)"
              >Export</span
            >
          </label>
          <div>
            <span class="text-score" v-if="!statLoading">
              {{ stat.totalRetentionPercent | numeral("0.00") }}%
            </span>
            <span v-else class="text-score"
              ><b-spinner type="grow" label="Spinning"></b-spinner>
            </span>
          </div>
        </b-col>
        <b-col md="3" class="border-right">
          <label class="w-100 mb-2 content-between f-16">
            Lapse
            <span
              class="f-16 underline cursor-pointer text-highlight"
              @click="exportModal(6, false)"
              >Export</span
            >
          </label>
          <div>
            <span class="text-score" v-if="!statLoading">
              {{ stat.totalLapsePercent | numeral("0.00") }}%
            </span>
            <span v-else class="text-score"
              ><b-spinner type="grow" label="Spinning"></b-spinner>
            </span>
          </div>
        </b-col>
        <b-col class="border-right">
          <label class="f-16">Total Revenue</label>
          <div>
            <span class="text-score" v-if="!statLoading">
              {{ stat.totalRevenue | numeral("0,0.00") }}
            </span>
            <span v-else class="text-score"
              ><b-spinner type="grow" label="Spinning"></b-spinner>
            </span>
          </div>
        </b-col>
      </b-row>
    </div>
    <b-container>
      <b-row>
        <b-col cols="12">
          <div class="shadow-sm mb-3 p-3">
            <b-table
              fixed
              striped
              hover
              :fields="fields.memberTier"
              :items="memberTier.items"
              :busy="memberTier.isBusy"
              show-empty
              empty-text="No matching records found"
              :current-page="memberTier.filter.page"
              :per-page="memberTier.filter.take"
              foot-clone
              no-footer-sorting
              @sort-changed="memberTierSorting"
              :sort-by.sync="memberTier.filter.sortBy"
              no-local-sorting
              :sort-desc.sync="memberTier.filter.sortDesc"
            >
              <template v-slot:cell(retentionRate)="data">
                <span>
                  {{ data.item.retentionRate | numeral("0,0.00") }}
                </span>
              </template>
              <template v-slot:cell(lapseRate)="data">
                <span>
                  {{ data.item.lapseRate | numeral("0,0.00") }}
                </span>
              </template>
              <template v-slot:cell(totalMembers)="data">
                <span>
                  {{ data.item.totalMembers | numeral("0,0") }}
                </span>
              </template>
              <template v-slot:cell(repurchaseMember)="data">
                <span>
                  {{ data.item.repurchaseMember | numeral("0,0") }}
                </span>
              </template>
              <template v-slot:cell(nonRepurechaseMember)="data">
                <span>
                  {{ data.item.nonRepurechaseMember | numeral("0,0") }}
                </span>
              </template>
              <template v-slot:foot()="data">
                <span v-if="memberTotal[data.field.key]" class="text-center"
                  >{{ memberTotal[data.field.key] | numeral("0,0") }}
                </span>
                <span v-else
                  ><div class="text-left px-2" v-if="data.field.text">
                    {{ data.field.text }}
                  </div>
                </span>
              </template>
            </b-table>
            <Pagination
              @pagination="paginationMemberTier"
              :filter="memberTier.filter"
              :rows="memberTier.rows"
              :hidePage="true"
              class="p-2"
            />
          </div>
        </b-col>
        <b-col
          cols="12"
          lg="6"
          v-for="chart in chartList"
          :key="chart.name"
          :class="['resize', { 'd-none': !chart.isChart }]"
        >
          <b-card
            class="shadow-sm border-none text-black rounded-lg"
            v-if="chart.isChart"
          >
            <div>
              <div class="mx-4 mb-3">
                <div class="content-between">
                  <div
                    :class="[
                      'chart-title-name',
                      { 'mb-1': chart.exportMode == 1 },
                    ]"
                  >
                    {{ chart.name }}
                  </div>
                  <div class="d-flex" style="column-gap: 5px">
                    <div
                      :class="`f-14 underline cursor-pointer`"
                      v-for="chartFilter in chart.filter"
                      :key="chartFilter + '-' + chart.name"
                      @click="handleChartFilter(chartFilter, chart)"
                    >
                      <span
                        :class="`${
                          chart.filterValue == chartFilter ||
                          chartFilter == 'Export'
                            ? 'text-highlight'
                            : 'text-gray'
                        }`"
                        >{{ chartFilter }}</span
                      >
                    </div>
                  </div>
                </div>

                <p v-if="chart.exportMode == 1" class="mb-0">
                  Revenue from Purchase Period
                  {{ chart.revenueFromPurchase | numeral("0,0.00") }}
                </p>
                <p v-if="chart.exportMode == 1">
                  Revenue from Repurchase Period
                  {{ chart.revenueFromRepurchase | numeral("0,0.00") }}
                </p>
              </div>
              <template v-if="chart.isFetch">
                <ChartLoading
                  :id="'loading-' + chart.name"
                  :type="chart.type"
                />
              </template>
              <template v-else>
                <BasketChart
                  v-if="chart.exportMode == 2 || chart.exportMode == 3"
                  :id="chart.name"
                  :chartData="chart.data"
                  :text="chart.text"
                  class="mt-auto"
                  :hideLegend="chart.hideLegend"
                  :label="chart.label"
                  :type="chart.type"
                  :filterValue="chart.filterValue"
                  :xText="chart.xText"
                />

                <LineChart
                  :ref="`chart-${chart.export}`"
                  v-else-if="chart.exportMode == 1"
                  :id="chart.name"
                  :chartData="chart.data"
                  class="mt-auto"
                  :text="chart.text"
                  :label="chart.label"
                  :filterValue="chart.filterValue"
                  :filter="filter"
                  xText="Date"
                />
              </template>
            </div>
          </b-card>
        </b-col>
        <b-col cols="12">
          <b-card class="shadow-sm border-none text-black rounded-lg">
            <div class="mb-2 content-between">
              <div class="chart-title-name">Product (Retention)</div>
              <div class="d-flex align-items-center" style="column-gap: 5px">
                <b-form-input
                  type="text"
                  id="header-search-bar"
                  name="search-product-retention"
                  class="search-bar mb-2"
                  @keyup.enter="handleSearchProductRetention"
                  placeholder="Search Name, Barcode"
                  v-debounce:500ms="handleSearchProductRetention"
                  v-model="productRetention.filter.search"
                  style="width: 300px"
                >
                </b-form-input>
                <div
                  class="f-14 underline cursor-pointer"
                  v-for="chartFilter of chartList[5].filter"
                  :key="chartFilter + '-' + chartList[5].name"
                  @click="handleChartFilter(chartFilter, chartList[5])"
                >
                  <span class="text-highlight">{{ chartFilter }}</span>
                </div>
              </div>
            </div>
            <b-table
              fixed
              striped
              hover
              :fields="fields.product"
              :items="productRetention.items"
              :busy="productRetention.isBusy"
              show-empty
              empty-text="No matching records found"
              foot-clone
              no-footer-sorting
              @sort-changed="productRetentionSorting"
              :sort-by.sync="productRetention.filter.sortBy"
              no-local-sorting
              :sort-desc.sync="productRetention.filter.sortDesc"
            >
              <template v-slot:cell(customer)="data">
                <span>
                  {{ data.item.customer | numeral("0,0") }}
                  ({{ data.item.customerPercent }}%)
                </span>
              </template>
              <template v-slot:cell(totalSales)="data">
                <span>
                  {{ data.item.totalSales | numeral("0,0.00") }}
                </span>
              </template>
              <template v-slot:cell(totalQuantity)="data">
                <span>
                  {{ data.item.totalQuantity | numeral("0,0") }}
                </span>
              </template>
              <template v-slot:cell(atv)="data">
                <span>
                  {{ data.item.atv | numeral("0,0.00") }}
                </span>
              </template>
              <template v-slot:cell(salesContribution)="data">
                <span>
                  {{ data.item.salesContribution | numeral("0,0.00") }} %
                </span>
              </template>
              <template v-slot:foot()="data">
                <span
                  v-if="totalProductReten[data.field.key]"
                  class="text-center"
                >
                  <span
                    v-if="
                      data.field.key == 'customer' ||
                      data.field.key == 'totalQuantity'
                    "
                  >
                    {{
                      totalProductReten[data.field.key] | numeral("0,0")
                    }}</span
                  >
                  <span v-else>
                    {{
                      totalProductReten[data.field.key] | numeral("0,0.00")
                    }}</span
                  >
                </span>
                <span v-else
                  ><div class="text-left px-2" v-if="data.field.text">
                    {{ data.field.text }}
                  </div>
                </span>
              </template>
            </b-table>
            <Pagination
              @handleChangeTake="handleChangeTakeProductRetention"
              @pagination="paginationProductRetention"
              :filter="productRetention.filter"
              :rows="productRetention.rows"
              :limit="2"
            />
          </b-card>
        </b-col>
        <b-col cols="12">
          <b-card class="shadow-sm border-none text-black rounded-lg mb-3">
            <div class="mb-2 content-between">
              <div class="chart-title-name">Product (Lapse)</div>
              <div class="d-flex align-items-center" style="column-gap: 5px">
                <b-form-input
                  type="text"
                  id="header-search-bar"
                  name="search-product-lapse"
                  class="search-bar mb-2"
                  @keyup.enter="handleSearchProductLapse"
                  placeholder="Search Name, Barcode"
                  v-debounce:500ms="handleSearchProductLapse"
                  v-model="productLapse.filter.search"
                  style="width: 300px"
                >
                </b-form-input>
                <div
                  class="f-14 underline cursor-pointer"
                  v-for="chartFilter of chartList[6].filter"
                  :key="chartFilter + '-' + chartList[6].name"
                  @click="handleChartFilter(chartFilter, chartList[6])"
                >
                  <span class="text-highlight">{{ chartFilter }}</span>
                </div>
              </div>
            </div>
            <b-table
              fixed
              striped
              hover
              :fields="fields.product"
              :items="productLapse.items"
              :busy="productLapse.isBusy"
              show-empty
              empty-text="No matching records found"
              foot-clone
              no-footer-sorting
              @sort-changed="productLapseSorting"
              :sort-by.sync="productLapse.filter.sortBy"
              no-local-sorting
              :sort-desc.sync="productLapse.filter.sortDesc"
            >
              <template v-slot:cell(customer)="data">
                <span>
                  {{ data.item.customer | numeral("0,0") }}
                  ({{ data.item.customerPercent }}%)
                </span>
              </template>
              <template v-slot:cell(totalSales)="data">
                <span>
                  {{ data.item.totalSales | numeral("0,0.00") }}
                </span>
              </template>
              <template v-slot:cell(totalQuantity)="data">
                <span>
                  {{ data.item.totalQuantity | numeral("0,0") }}
                </span>
              </template>
              <template v-slot:cell(atv)="data">
                <span>
                  {{ data.item.atv | numeral("0,0.00") }}
                </span>
              </template>
              <template v-slot:cell(salesContribution)="data">
                <span>
                  {{ data.item.salesContribution | numeral("0,0.00") }} %
                </span>
              </template>
              <template v-slot:foot()="data">
                <span
                  v-if="totalProductLaspe[data.field.key]"
                  class="text-center"
                >
                  <span
                    v-if="
                      data.field.key == 'customer' ||
                      data.field.key == 'totalQuantity'
                    "
                  >
                    {{
                      totalProductLaspe[data.field.key] | numeral("0,0")
                    }}</span
                  >
                  <span v-else>
                    {{
                      totalProductLaspe[data.field.key] | numeral("0,0.00")
                    }}</span
                  >
                </span>
                <span v-else
                  ><div class="text-left px-2" v-if="data.field.text">
                    {{ data.field.text }}
                  </div>
                </span>
              </template>
            </b-table>
            <Pagination
              @handleChangeTake="handleChangeTakeProductLapse"
              @pagination="paginationProductLapse"
              :filter="productLapse.filter"
              :rows="productLapse.rows"
              :limit="2"
            />
          </b-card>
        </b-col>
      </b-row>
    </b-container>
    <SideBarFilter
      id="retentionFilter"
      ref="retentionFilter"
      :filter="filter"
      :placeholder="'Search Name'"
      @clearFilter="clearFilter"
      @searchAll="filterActions"
      :hideStatusFilter="false"
      :hideSearchBar="true"
    >
      <template v-slot:filter-option>
        <div class="mb-3">
          <InputDatePicker
            textFloat="Purchase Date From"
            name="purchase-date-from"
            ref="purchase-date-from"
            v-model="filter.PurchaseFrom"
          />
        </div>
        <div class="mb-3">
          <InputDatePicker
            textFloat="Purchase Date To"
            name="purchase-date-to"
            ref="purchase-date-to"
            :min-datetime="filter.PurchaseFrom"
            :max-datetime="filter.RepurchaseFrom"
            v-model="filter.PurchaseTo"
          />
        </div>
        <div class="mb-3">
          <InputDatePicker
            textFloat="Repurchase Date From"
            name="repurchase-date-from"
            ref="repurchase-date-from"
            :min-datetime="filter.PurchaseTo"
            v-model="filter.RepurchaseFrom"
          />
        </div>
        <div class="mb-3">
          <InputDatePicker
            textFloat="Repurchase Date To"
            name="repurchase-date-to"
            ref="repurchase-date-to"
            :min-datetime="filter.PurchaseTo"
            v-model="filter.RepurchaseTo"
          />
        </div>
        <div class="mb-3">
          <InputDatePicker
            textFloat="First Purchase Date From"
            name="first-purchase-date-from"
            ref="first-purchase-date-from"
            v-model="filter.FirstPurchaseDateFrom"
          />
        </div>
        <div class="mb-3">
          <InputDatePicker
            textFloat="First Purchase Date To"
            name="first-purchase-date-to"
            ref="first-purchase-date-to"
            v-model="filter.FirstPurchaseDateTo"
          />
        </div>
        <template v-if="!isFilterLoading">
          <div class="mb-3">
            <label class="main-label"> Member tier</label>
            <multiselect
              :close-on-select="false"
              :clear-on-select="false"
              multiple
              v-model="filter.MemberTierFilter"
              :options="options.memberTiers"
              placeholder="Select Member Tier"
              track-by="id"
              label="memberTierName"
            ></multiselect>
          </div>
          <div class="mb-3">
            <label class="main-label">Barcode / Product</label>
            <multiselect
              :close-on-select="false"
              :clear-on-select="false"
              multiple
              v-model="filter.ProductFilter"
              :options="options.product"
              placeholder="Select Barcode / Product"
              track-by="id"
              label="productName"
            ></multiselect>
          </div>
          <div class="mb-3">
            <label class="main-label"> Age Group</label>
            <multiselect
              :close-on-select="false"
              :clear-on-select="false"
              multiple
              v-model="filter.AgeGroupFilter"
              :options="options.ageGroups"
              placeholder="Select Age Group"
              track-by="id"
              label="ageGroupName"
            ></multiselect>
          </div>
          <div class="mb-3">
            <label class="main-label">Purchase Branch</label>
            <multiselect
              :close-on-select="false"
              :clear-on-select="false"
              multiple
              v-model="filter.BranchFilter"
              :options="options.branches"
              placeholder="Select Purchase Branch"
              track-by="id"
              label="branchName"
            ></multiselect>
          </div>
          <div class="mb-3">
            <label class="main-label">Gender</label>
            <multiselect
              :close-on-select="false"
              :clear-on-select="false"
              multiple
              v-model="filter.GenderFilter"
              :options="options.genderModels"
              placeholder="Select Category List"
              track-by="id"
              label="genderGroupName"
            ></multiselect>
          </div>
        </template>
      </template>
    </SideBarFilter>
    <ModalInputEmail
      ref="ModalInputEmail"
      :form="filterExportFile"
      @closeModal="clearExportFilter"
      @changeEmail="(val) => (filterExportFile.email = val)"
      @submit="exportFile(filterExportFile.mode, filterExportFile.isRe)"
    />
  </div>
</template>

<script>
import moment from "moment";
import LineChart from "./LineChart";
import BasketChart from "./BasketChart";
import ChartLoading from "@/components/loading/ChartLoading";
import Multiselect from "vue-multiselect";
import ModalInputEmail from "@/components/customer/ModalInputEmail";

function getRandomColor() {
  const r = Math.floor(Math.random() * 256);
  const g = Math.floor(Math.random() * 256);
  const b = Math.floor(Math.random() * 256);
  return "rgb(" + r + "," + g + "," + b + ")";
}
export default {
  components: {
    Multiselect,
    LineChart,
    BasketChart,
    ChartLoading,

    ModalInputEmail,
  },
  data() {
    return {
      isFilterLoading: true,
      filterExportFile: {
        email: "",
        mode: "",
        isRetention: "",
      },
      filter: {
        PurchaseFrom: "",
        PurchaseTo: "",
        RepurchaseFrom: "",
        RepurchaseTo: "",
        FirstPurchaseDateFrom: "",
        FirstPurchaseDateTo: "",
        MemberTier: [],
        BranchFilter: [],
        Product: [],
        AgeGroup: [],
        Gender: [],
        GraphType: 2,
      },
      filterFreeze: {
        PurchaseFrom: "",
        PurchaseTo: "",
        RepurchaseFrom: "",
        RepurchaseTo: "",
        FirstPurchaseDateFrom: "",
        FirstPurchaseDateTo: "",
        MemberTier: [],
        BranchFilter: [],
        Product: [],
        AgeGroup: [],
        Gender: [],
        GraphType: 2,
      },
      memberTotal: {
        totalMember: 0,
        totalRepurchaseMember: 0,
        nonRepurechaseMember: 0,
      },
      options: {
        memberTiers: [],
        channelTypes: [],
        branches: [],
        ageGroups: [],
        genderModels: [],
      },
      chartList: [
        { name: "Member Tier", filter: [] },
        {
          name: "Total Revenue by date (Retention)",
          filterValue: "Month",
          text: "Total Revenue",
          filter: ["Day", "Month", "Year", "Export"],
          label: [],
          data: [],
          export: "ReportMemberTier",
          get: () => this.getDateRetention(),
          isFetch: false,
          exportMode: 1,
          IsRetention: true,
          isChart: true,
          revenueFromPurchase: 0,
          revenueFromRepurchase: 0,
        },
        {
          name: "Total Revenue by date (Lapse)",
          filterValue: "Month",
          text: "Total Revenue",
          filter: ["Day", "Month", "Year", "Export"],
          label: [],
          data: [],
          export: "ReportChannel",
          get: () => this.getDateLapse(),
          isFetch: false,
          exportMode: 1,
          IsRetention: false,
          isChart: true,
          revenueFromPurchase: 0,
          revenueFromRepurchase: 0,
        },
        {
          name: "Age",
          filterValue: "",
          text: "Number Of Member",
          filter: ["Export"],
          label: [],
          data: [],
          get: () => this.getAge(),
          type: "bar",
          isFetch: false,
          exportMode: 2,
          isChart: true,
          xText: "Age Group",
        },
        {
          name: "Gender",
          filterValue: "",
          text: "Number Of Member",
          filter: ["Export"],
          label: [],
          data: [],
          get: () => this.getGender(),
          type: "bar",
          isFetch: false,
          exportMode: 3,
          isChart: true,
          xText: "Gender",
        },
        {
          name: "Product (Retention)",
          filter: ["Export"],
          exportMode: 4,
          IsRetention: true,
        },
        {
          name: "Product (Lapse)",
          filter: ["Export"],
          exportMode: 4,
          IsRetention: false,
        },
      ],
      fields: {
        memberTier: [
          {
            key: "memberTierName",
            label: "Member Tier",
            sortable: true,
            text: "Total",
            class: "w-auto",
          },
          {
            key: "retentionRate",
            label: "Retention Rate",
            sortable: true,
            class: "w-1",
          },
          {
            key: "lapseRate",
            label: "Lapse Rate",
            sortable: true,
            class: "w-1",
          },
          {
            key: "totalMembers",
            label: "Total Members",
            sortable: true,
            class: "w-1",
          },
          {
            key: "repurchaseMember",
            label: "Repurchase Member",
            sortable: true,
            class: "w-2",
          },
          {
            key: "nonRepurechaseMember",
            label: "Non Repurchase Member",
            sortable: true,
            class: "w-2",
          },
        ],
        product: [
          {
            key: "barcode",
            label: "Barcode",
            sortable: true,
            text: "Total",
            class: "w-2",
          },
          {
            key: "name",
            label: "Name",
            sortable: true,
            thClass: "w-5",
          },
          {
            key: "customer",
            label: "% Customer",
            sortable: true,
            class: "w-2",
          },
          {
            key: "totalSales",
            label: "Total Sales",
            sortable: true,
            class: "w-2",
          },
          {
            key: "totalQuantity",
            label: "Total Quantity",
            sortable: true,
            class: "w-2",
          },
          {
            key: "atv",
            label: "ATV",
            sortable: true,
            class: "w-1",
          },
          {
            key: "salesContribution",
            label: "% Sales Contribution",
            sortable: true,
            class: "w-2",
          },
        ],
      },
      memberTier: {
        items: [],
        isBusy: false,
        rows: 0,
        filter: {
          page: 1,
          take: 9999,
          SortColumnId: 0,
          SortType: true,
          sortBy: "",
          sortDesc: "",
        },
      },
      productRetention: {
        items: [],
        isBusy: false,
        rows: 0,
        filter: {
          search: "",
          page: 1,
          take: 10,
        },
      },
      productLapse: {
        items: [],
        isBusy: false,
        rows: 0,
        filter: {
          search: "",
          page: 1,
          take: 10,
        },
      },
      stat: {
        totalCustomer: 0,
        totalCustomerPercent: 0,
        totalLapsePercent: 0,
        totalRetentionPercent: 0,
        totalRevenue: 0,
      },
      initFilterDate: {
        MinDate: "",
        MaxDate: "",
        PurchaseFrom: "",
        PurchaseTo: "",
        RepurchaseFrom: "",
        RepurchaseTo: "",
        FirstPurchaseDateFrom: "",
        FirstPurchaseDateTo: "",
      },
      totalProductReten: {},
      totalProductLaspe: {},
      statLoading: true,
    };
  },
  async mounted() {
    this.initDateFilter();
    await this.getFilter();
    await this.getProductFilter();
    await this.getData();
  },
  methods: {
    exportModal(mode, isRe) {
      this.filterExportFile.mode = mode;
      this.filterExportFile.isRetention = isRe;
      this.exportFile(mode, isRe);
      // this.$refs.ModalInputEmail.show();
    },
    initDateFilter() {
      let toDay = moment().format("YYYY-MM-DDT00:00:00");
      this.initFilterDate.PurchaseFrom = moment(toDay).day(-14).format();
      this.initFilterDate.PurchaseTo = moment(toDay).day(-7).format();
      this.initFilterDate.RepurchaseFrom = this.initFilterDate.PurchaseTo;
      this.initFilterDate.RepurchaseTo = toDay;
      this.initFilterDate.MinDate = moment(toDay).day(-365).format();
      this.initFilterDate.MaxDate = toDay;
      this.filter.PurchaseFrom = this.initFilterDate.PurchaseFrom;
      this.filter.PurchaseTo = this.initFilterDate.PurchaseTo;
      this.filter.RepurchaseFrom = this.initFilterDate.RepurchaseFrom;
      this.filter.RepurchaseTo = this.initFilterDate.RepurchaseTo;
    },
    async getData() {
      this.filterFreeze = { ...this.filter };

      return await Promise.all([
        // (this.isBusy = true),
        this.getStat(),
        this.getMemberTier(),
        this.getDateRetention(),
        this.getDateLapse(),
        this.getAge(),
        this.getGender(),
        this.getProductRetention(),
        this.getProductLapse(),
        // (this.isBusy = false),
      ]);
    },
    handleDateFormat(type, date) {
      // if (type === 1) value = moment(date, "d/DD/YYYY").format("DD/MM/YYYY");
      return date;
    },
    async getDateRetention() {
      this.chartList[1].isFetch = true;
      let body = {
        ...this.filterFreeze,
        IsRetention: true,
      };
      const res = await this.$Axios.post(
        `${process.env.VUE_APP_REPORT_API}/Dashboard/Retention/Graph/Date`,
        body
      );
      let label = [];
      var dataSet = [];
      let dataList = [];

      dataList = await res.data.detail.data.sort(function (a, b) {
        if (a.order < b.order) return -1;
        else if (a.order > b.order) return 1;
        return 0;
      });

      if (dataList.length > 0) {
        await dataList[0].data.forEach((element, index) => {
          let color = getRandomColor();
          dataSet.push({
            order: index,
            label: element.name,
            data: [],
            type: "line",
            borderColor: color,
            backgroundColor: color,
            fill: false,
          });
        });

        if (this.filterFreeze.GraphType == 1) {
          await dataList.map(
            (el) =>
              (el.group = this.handleDateFormat(
                this.filterFreeze.GraphType,
                el.group
              ))
          );
        }

        await dataList.forEach((item) => {
          label.push(item.group);
        });

        for (const val of dataSet) {
          let tempObj = [];

          for (const list of dataList) {
            let data = list.data.find((el) => el.name == val.label);
            tempObj.push(data.value);
          }
          val.data = tempObj;
        }
      }

      this.chartList[1].label = label;
      this.chartList[1].data = dataSet;
      this.chartList[1].isFetch = false;
      this.chartList[1].revenueFromPurchase =
        res.data.detail.revenueFromPurchase;
      this.chartList[1].revenueFromRepurchase =
        res.data.detail.revenueFromRepurchase;
    },
    async getDateLapse() {
      this.chartList[2].isFetch = true;
      let body = {
        ...this.filterFreeze,
        IsRetention: false,
      };
      const res = await this.$Axios.post(
        `${process.env.VUE_APP_REPORT_API}/Dashboard/Retention/Graph/Date`,
        body
      );
      let label = [];
      var dataSet = [];
      let dataList = [];

      dataList = await res.data.detail.data.sort(function (a, b) {
        if (a.order < b.order) return -1;
        else if (a.order > b.order) return 1;
        return 0;
      });

      if (dataList.length > 0) {
        await dataList[0].data.forEach((element, index) => {
          let color = getRandomColor();
          dataSet.push({
            order: index,
            label: element.name,
            type: "line",
            data: [],
            borderColor: color,
            backgroundColor: color,
            fill: false,
          });
        });

        if (this.filterFreeze.GraphType == 1) {
          await dataList.map(
            (el) =>
              (el.group = this.handleDateFormat(
                this.filterFreeze.GraphType,
                el.group
              ))
          );
        }

        await dataList.forEach((item) => {
          label.push(item.group);
        });

        for (const val of dataSet) {
          let tempObj = [];

          for (const list of dataList) {
            let data = list.data.find((el) => el.name == val.label);
            tempObj.push(data.value);
          }
          val.data = tempObj;
        }
      }

      this.chartList[2].label = label;
      this.chartList[2].data = dataSet;
      this.chartList[2].isFetch = false;
      this.chartList[2].revenueFromPurchase =
        res.data.detail.revenueFromPurchase;
      this.chartList[2].revenueFromRepurchase =
        res.data.detail.revenueFromRepurchase;
    },
    async getAge() {
      this.chartList[3].isFetch = true;
      const res = await this.$Axios.post(
        `${process.env.VUE_APP_REPORT_API}/Dashboard/Retention/Graph/Age`,
        this.filterFreeze
      );
      let label = [];
      var dataSet = [];

      for (let index = 0; index < 2; index++) {
        var style = getComputedStyle(document.body);
        let color =
          index == 0
            ? style.getPropertyValue("--primary-color")
            : style.getPropertyValue("--secondary-color");
        dataSet.push({
          yAxisID: "A",
          order: index,
          type: "bar",
          label: index === 0 ? "Retention" : "Lapse",
          data: [],
          pointStyle: "triangle",
          borderColor: color,
          backgroundColor: color,
          fill: false,
        });
      }

      await res.data.detail.forEach((element) => {
        // if (element.name != "N/A") {
        label.push(element.name);
        dataSet[0].data.push(element.value);
        dataSet[1].data.push(element.value2);
        // }
      });

      this.chartList[3].label = label;
      this.chartList[3].data = dataSet;
      this.chartList[3].isFetch = false;
      this.chartList[2].revenueFromPurchase =
        res.data.detail.revenueFromPurchase;
      this.chartList[2].revenueFromRepurchase =
        res.data.detail.revenueFromRepurchase;
    },
    async getGender() {
      this.chartList[4].isFetch = true;
      const res = await this.$Axios.post(
        `${process.env.VUE_APP_REPORT_API}/Dashboard/Retention/Graph/Gender`,
        this.filterFreeze
      );
      let label = [];
      var dataSet = [];

      for (let index = 0; index < 2; index++) {
        let labelName = index == 0 ? "Retention" : "Lapse";
        var style = getComputedStyle(document.body);
        let color =
          index == 0
            ? style.getPropertyValue("--primary-color")
            : style.getPropertyValue("--secondary-color");
        dataSet.push({
          yAxisID: "A",
          order: index,
          type: "bar",
          label: labelName,
          data: [],
          pointStyle: "triangle",
          borderColor: color,
          backgroundColor: color,
          fill: false,
        });
      }

      await res.data.detail.forEach((element, index) => {
        let labelName = res.data.detail[index].name;
        label.push(labelName);
        dataSet[0].data.push(element.value);
        dataSet[1].data.push(element.value2);
      });

      this.chartList[4].label = label;
      this.chartList[4].data = dataSet;
      this.chartList[4].isFetch = false;
    },
    async getStat() {
      // this.isBusy = true;
      this.statLoading = true;
      const res = await this.$Axios.post(
        `${process.env.VUE_APP_REPORT_API}/Dashboard/Retention`,
        this.filterFreeze
      );
      const isProductRe = await this.$Axios.post(
        `${process.env.VUE_APP_REPORT_API}/Dashboard/Retention/Product_Subtotal`,
        {
          ...this.filterFreeze,
          Search: this.productRetention.filter.search,
          Page: this.productRetention.filter.page,
          take: this.productRetention.filter.take,
          SortColumnId: this.productRetention.filter.SortColumnId,
          SortType: this.productRetention.filter.SortType,
          IsRetention: true,
        }
      );
      const isProductLapse = await this.$Axios.post(
        `${process.env.VUE_APP_REPORT_API}/Dashboard/Retention/Product_Subtotal`,
        {
          ...this.filterFreeze,
          Search: this.productLapse.filter.search,
          Page: this.productLapse.filter.page,
          take: this.productLapse.filter.take,
          SortColumnId: this.productLapse.filter.SortColumnId,
          SortType: this.productLapse.filter.SortType,
          IsRetention: false,
        }
      );
      this.totalProductLaspe = isProductLapse.data.detail;
      this.totalProductReten = isProductRe.data.detail;
      this.stat = res.data.detail;
      let date = `${this.$moment(this.filter.PurchaseFrom).format(
        "DD MMM YYYY"
      )} - ${this.$moment(this.filter.PurchaseTo).format("DD MMM YYYY")}`;
      let stat = {
        totalCustomer: res.data.detail.totalCustomer,
        percentCustomer: res.data.detail.totalCustomerPercent,
      };
      this.statLoading = false;
      this.$emit("setStat", stat, date);
    },
    async getProductRetention() {
      this.productRetention.isBusy = true;
      const res = await this.$Axios.post(
        `${process.env.VUE_APP_REPORT_API}/Dashboard/Retention/product`,
        {
          ...this.filterFreeze,
          Search: this.productRetention.filter.search,
          Page: this.productRetention.filter.page,
          take: this.productRetention.filter.take,
          SortColumnId: this.productRetention.filter.SortColumnId,
          SortType: this.productRetention.filter.SortType,
          IsRetention: true,
        }
      );

      this.productRetention.items = res.data.detail.data;
      this.productRetention.rows = res.data.detail.total;
      this.productRetention.isBusy = false;
    },
    async getProductLapse() {
      this.productLapse.isBusy = true;
      const res = await this.$Axios.post(
        `${process.env.VUE_APP_REPORT_API}/Dashboard/Retention/product`,
        {
          ...this.filterFreeze,
          Search: this.productLapse.filter.search,
          Page: this.productLapse.filter.page,
          take: this.productLapse.filter.take,
          SortColumnId: this.productLapse.filter.SortColumnId,
          SortType: this.productLapse.filter.SortType,
          IsRetention: false,
        }
      );

      this.productLapse.items = res.data.detail.data;
      this.productLapse.rows = res.data.detail.total;
      this.productLapse.isBusy = false;
    },
    async getMemberTier() {
      this.memberTier.isBusy = true;
      let payload = { ...this.filterFreeze };
      payload.SortColumnId = this.memberTier.filter.SortColumnId;
      payload.SortType = this.memberTier.filter.SortType;
      const res = await this.$Axios.post(
        `${process.env.VUE_APP_REPORT_API}/Dashboard/Retention/Data`,
        payload
      );

      this.memberTier.items = res.data.detail.result;
      this.memberTier.rows = res.data.detail.length;
      this.memberTier.isBusy = false;
      this.memberTotal = res.data.detail.subtotal;
    },
    paginationMemberTier(val) {
      this.memberTier.filter.page = val;
    },
    paginationProductRetention(val) {
      this.productRetention.filter.page = val;
      this.getProductRetention();
    },
    handleChangeTakeProductRetention(val) {
      this.productRetention.filter.take = val;
      this.productRetention.filter.page = 1;
      this.getProductRetention();
    },
    paginationProductLapse(val) {
      this.productLapse.filter.page = val;
      this.getProductLapse();
    },
    handleChangeTakeProductLapse(val) {
      this.productLapse.filter.take = val;
      this.productLapse.filter.page = 1;
      this.getProductLapse();
    },
    async getProductFilter() {
      const res = await this.$Axios.post(
        `${process.env.VUE_APP_REPORT_API}/RevenueReport/CustomProductFilter`,
        {
          SearchProduct: "",
          PageNumber: 1,
          RecordPerpage: 9999,
        }
      );
      this.options.product = res.data.productFilterModels.map((el) => {
        return { id: el.id, productName: `${el.barcode} / ${el.productName}` };
      });
      this.isFilterLoading = false;
    },
    async getFilter() {
      const res = await this.$Axios(
        `${process.env.VUE_APP_REPORT_API}/RevenueReport/CustomFilter`
      );

      this.options = {
        ...res.data,
      };
      // this.isFilterLoading = false;
    },
    async exportFile(mode, isRetention) {
      let body = {
        ...this.filter,
        Search: "",
        Page: 1,
        take: 9999,
        IsRetention: isRetention,
        Mode: mode,
      };
      let url = `${process.env.VUE_APP_REPORT_API}/Dashboard/Export/Retention`;
      let fileName = "";
      if (mode == 1) {
        if (isRetention) fileName = "Total-Sales-By-Date-Retention";
        else fileName = "Total-Sales-By-Date-Lapse";
      } else if (mode == 2) {
        fileName = "Age";
      } else if (mode == 3) {
        fileName = "Gender";
      } else if (mode == 4) {
        if (isRetention) {
          fileName = "Product-Retention";
          body.Search = this.productRetention.filter.search;
        } else {
          body.Search = this.productLapse.filter.search;
          fileName = "Product-Lapse";
        }
      } else if (mode == 5) {
        body.email = this.filterExportFile.email;
        url = `${process.env.VUE_APP_REPORT_API}/DashBoard/Export/excel_retention`;
        fileName = "Retention";
      } else if (mode == 6) {
        body.email = this.filterExportFile.email;
        url = `${process.env.VUE_APP_REPORT_API}/DashBoard/Export/excel_retention`;
        fileName = "Lapse";
      }
      this.$EventBus.$emit("showLoading");

      const res = await this.$Axios.post(url, body, {
        responseType: "blob",
      });

      if (mode == 5 || mode == 6) {
        this.confirmAlert({
          message: "Do you want to download the file now?",
          title: "Success !",
          icon: "success",
          confirmButtonText: "Go To Page",
          cancelButtonText: "Close",
        }).then((val) => {
          if (val.isConfirmed) {
            // this.deleteItem(id);
            const routeData = this.$router.resolve({
              name: "Customer Report List",
            });
            window.open(routeData.href, "_blank");
          }
        });
      } else {
        let data = res;

        var fileURL = window.URL.createObjectURL(new Blob([data.data]));
        var fileLink = document.createElement("a");
        // var dateExcel = this.$moment().format("DDMMYYYYhhmmss");
        fileLink.href = fileURL;
        fileLink.setAttribute("download", fileName + `.xlsx`);
        document.body.appendChild(fileLink);
        fileLink.click();
      }
      this.$EventBus.$emit("hideLoading");
    },
    async handleChartFilter(filter, chart) {
      let value = 0;
      if (filter == "Day") value = 1;
      if (filter == "Month") value = 2;
      if (filter == "Year") value = 3;
      if (filter == "Export")
        return this.exportFile(chart.exportMode, chart.IsRetention);
      if (filter != "Export") chart.filterValue = filter;
      this.filter.GraphType = value;
      this.filterFreeze.GraphType = value;
      await chart.get();
    },
    clearFilter() {
      this.filter = {
        PurchaseFrom: this.initFilterDate.PurchaseFrom,
        PurchaseTo: this.initFilterDate.PurchaseTo,
        RepurchaseFrom: this.initFilterDate.RepurchaseFrom,
        RepurchaseTo: this.initFilterDate.RepurchaseTo,
        FirstPurchaseDateFrom: this.initFilterDate.FirstPurchaseDateFrom,
        FirstPurchaseDateTo: this.initFilterDate.FirstPurchaseDateTo,
        MemberTier: [],
        Product: [],
        AgeGroup: [],
        Gender: [],
        GraphType: 2,
      };
      this.filterFreeze = {
        PurchaseFrom: this.initFilterDate.PurchaseFrom,
        PurchaseTo: this.initFilterDate.PurchaseTo,
        RepurchaseFrom: this.initFilterDate.RepurchaseFrom,
        RepurchaseTo: this.initFilterDate.RepurchaseTo,
        FirstPurchaseDateFrom: this.initFilterDate.FirstPurchaseDateFrom,
        FirstPurchaseDateTo: this.initFilterDate.FirstPurchaseDateTo,
        MemberTier: [],
        Product: [],
        AgeGroup: [],
        Gender: [],
        GraphType: 2,
      };
      this.$refs.retentionFilter.hide();
      this.filterActions();
    },
    filterActions() {
      if (this.filter.FirstPurchaseDateFrom)
        this.filter.FirstPurchaseDateFrom = this.$moment(
          this.filter.FirstPurchaseDateFrom
        ).format("YYYY-MM-DD");
      if (this.filter.FirstPurchaseDateTo)
        this.filter.FirstPurchaseDateTo = this.$moment(
          this.filter.FirstPurchaseDateTo
        ).format("YYYY-MM-DD");

      this.filterFreeze = { ...this.filter };
      for (const [keys, value] of Object.entries(this.filter)) {
        if (typeof value == "object") {
          this.filterFreeze[keys] = value.map((el) => el.id);
        }
      }
      this.getData();
    },
    handleSearchProductRetention() {
      this.getProductRetention();
    },
    handleSearchProductLapse() {
      this.getProductLapse();
    },
    handleInput(val) {
      this.filter.PurchaseTo = val;
    },
    clearExportFilter() {
      this.filterExportFile = {
        email: "",
      };
    },
    memberTierSorting(ctx) {
      let index = this.fields.memberTier.findIndex(
        (el) => el.key == ctx.sortBy
      );
      index = index + 1;

      if (
        this.memberTier.filter.SortType &&
        !ctx.sortDesc &&
        this.memberTier.filter.SortColumnId == index
      ) {
        this.memberTier.filter.SortColumnId = 0;
        this.memberTier.filter.SortColumnId = 0;
        this.memberTier.filter.sortBy = "";
        this.memberTier.filter.sortDesc = "";
      } else {
        this.memberTier.filter.SortColumnId = index;
        this.memberTier.filter.SortType = ctx.sortDesc;
      }
      this.getMemberTier();
    },
    productRetentionSorting(ctx) {
      let index = this.fields.product.findIndex((el) => el.key == ctx.sortBy);
      index = index + 1;

      if (
        this.productRetention.filter.SortType &&
        !ctx.sortDesc &&
        this.productRetention.filter.SortColumnId == index
      ) {
        this.productRetention.filter.SortColumnId = 0;

        this.productRetention.filter.sortBy = "";
        this.productRetention.filter.sortDesc = "";
      } else {
        this.productRetention.filter.SortColumnId = index;
        this.productRetention.filter.SortType = ctx.sortDesc;
      }
      this.getProductRetention();
    },
    productLapseSorting(ctx) {
      let index = this.fields.product.findIndex((el) => el.key == ctx.sortBy);
      index = index + 1;

      if (
        this.productLapse.filter.SortType &&
        !ctx.sortDesc &&
        this.productLapse.filter.SortColumnId == index
      ) {
        this.productLapse.filter.SortColumnId = 0;
        this.productLapse.filter.sortBy = "";
        this.productLapse.filter.sortDesc = "";
      } else {
        this.productLapse.filter.SortColumnId = index;
        this.productLapse.filter.SortType = ctx.sortDesc;
      }
      this.getProductLapse();
    },
  },
};
</script>

<style>
.border-none {
  border: unset;
}
.underline {
  text-decoration: underline;
}
.text-highlight {
  color: var(--primary-color);
  font-weight: bold;
}
.text-gray {
  color: whitesmoke;
}
.chart-title-name {
  font-size: 18px;
  font-weight: bold;
  color: black;
  /* margin-bottom: 0.75rem; */
}
.text-score {
  color: var(--primary-color);
  font-size: 42px;
}
.input-select .select-custom label {
  color: var(--font-main-color);
  font-size: 16px;
  margin-bottom: 2px;
  font-weight: bold;
}
</style>
